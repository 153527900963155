const tag = (category: string, label: string, value?: number) => {
  if (typeof window !== "undefined" && window["gtag"]) {
    window["gtag"]("event", "click", {
      event_category: category,
      event_label: label,
      value: value,
    })
  }
}

export default tag
