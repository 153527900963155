import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Link from '../utils/link'
import tag from "../utils/tag"
//import Fade from 'react-reveal/Fade'

class ReadyToGetStarted extends Component {

  render() {

    let { title, content, items } = this.props.data.wp.siteOptions.settings.ready

    return (

      <section className='ready-to-get-started'>

        <div className='ready-to-get-started__inner'>

          <div className='ready-to-get-started__title'>
            <h2>{ title }</h2>
          </div>

          <div className='ready-to-get-started__content' dangerouslySetInnerHTML={{ __html: content }} />

          <div className='ready-to-get-started__items'>

            {items.map((el, i) => (

                <div className='ready-to-get-started__item' key={i}>

                  <div className='ready-to-get-started__item-image'>

                      <GatsbyImage loading='lazy' image={getImage(el.image?.localFile)} alt={el.title} />

                  </div>

                  <div className='ready-to-get-started__item-title'>

                    <h3 className='ready-to-get-started__item-heading'>{ el.title }</h3>

                  </div>

                  <div className='ready-to-get-started__item-content' dangerouslySetInnerHTML={{ __html: el.content }} />

                  <Link className='ready-to-get-started__item-button btn btn--secondary' onClick={() => {
                    if (el?.button?.link?.includes("tel")) {
                      tag("Contact Action", "Click To Call")
                    } else if (el?.button?.link?.includes("mailto")) {
                      tag("Contact Action", "Click To Email")
                    }
                  }} to={ el.button.link }>{ el.button.text }</Link>

                </div>

            ))}

          </div>

        </div>

      </section>
    )
  }
}

export default function ReadyQuery(props) {
  return (
    <StaticQuery
      query={graphql`
        query ReadyQuery {
          wp {
            siteOptions {
              settings {
                ready {
                  title
                  content
                  items {
                    title
                    content
                    image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            quality: 75
                            width: 200
                            formats: [AUTO, WEBP, AVIF]
                            placeholder: DOMINANT_COLOR
                          )
                        }
                      }
                    }
                    button {
                      text
                      link
                    }
                  } 
                }
              }
            }
          }
        }
      `}
      render={data => (
        <ReadyToGetStarted data={data} {...props} />
      )}
    />
  )
}
